var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "480",
            "max-height": "400",
            persistent: "",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "overflow-hidden" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 ml-4" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                    },
                    [_vm._v(_vm._s(_vm.$t("secondNoticeTicket")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "col-2 my-auto",
                      attrs: { "aria-label": "cross", icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [_c("v-divider")],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-4",
                  class: _vm.reasonErrors.length === 0 ? "mb-6" : "mb-0",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.reasonOptions,
                      "menu-props": { bottom: true, offsetY: true },
                      outlined: "",
                      dense: "",
                      required: "",
                      "item-value": "value",
                      "hide-details": _vm.reasonErrors.length === 0,
                      "error-messages": _vm.reasonErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.reason.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.reason.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(" *"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.$t("reason")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.reason,
                      callback: function ($$v) {
                        _vm.reason = $$v
                      },
                      expression: "reason",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-2 mb-0",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menu1",
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        "persistent-hint": "",
                                        outlined: "",
                                        dense: "",
                                        "prepend-inner-icon": "mdi-calendar",
                                        "error-messages":
                                          _vm.dateFormattedErrors,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.workDate.$touch()
                                        },
                                        blur: function ($event) {
                                          return _vm.$v.workDate.$touch()
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "span",
                                                  { staticClass: "red--text" },
                                                  [_vm._v(" *")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("WorkDate")) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.workDate,
                                        callback: function ($$v) {
                                          _vm.workDate = $$v
                                        },
                                        expression: "workDate",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu1,
                        callback: function ($$v) {
                          _vm.menu1 = $$v
                        },
                        expression: "menu1",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          min: _vm.getNowDate,
                          max: _vm.getEndDate,
                        },
                        on: {
                          input: function ($event) {
                            _vm.menu1 = false
                          },
                        },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-0",
                  class: _vm.remarksErrors.length === 0 ? "mb-6" : "mb-0",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      label: "Remarks",
                      required: "",
                      rows: "2",
                      "hide-details": _vm.remarksErrors.length === 0,
                      "error-messages": _vm.remarksErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.remarks.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.remarks.$touch()
                      },
                    },
                    model: {
                      value: _vm.remarks,
                      callback: function ($$v) {
                        _vm.remarks = $$v
                      },
                      expression: "remarks",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-2",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.membersToNotifyList,
                      "menu-props": { bottom: true, offsetY: true },
                      label: _vm.$t("membersToNotify"),
                      outlined: "",
                      dense: "",
                      required: "",
                      multiple: "",
                      "item-text": "mbcode_name",
                      "item-value": "mbcode",
                      "error-messages": _vm.membersToAddError,
                      "search-input": _vm.searchMembers,
                    },
                    on: {
                      change: _vm.updateMemberCode,
                      input: function ($event) {
                        return _vm.$v.membersToAddListString.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.membersToAddListString.$touch()
                      },
                      "update:searchInput": function ($event) {
                        _vm.searchMembers = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.searchMembers = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-item",
                        fn: function () {
                          return [
                            _c(
                              "v-list-item",
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    "prepend-inner-icon": "mdi-magnify",
                                    label: _vm.$t("searchMembersToNotify"),
                                    "single-line": "",
                                    "hide-details": "",
                                    flat: "",
                                    dense: "",
                                    variant: "underlined",
                                    "clear-icon": "mdi-close-circle",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchMembers,
                                    callback: function ($$v) {
                                      _vm.searchMembers = $$v
                                    },
                                    expression: "searchMembers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "selection",
                        fn: function (ref) {
                          var attrs = ref.attrs
                          var item = ref.item
                          var parent = ref.parent
                          var selected = ref.selected
                          return [
                            _c(
                              "v-chip",
                              _vm._b(
                                {
                                  attrs: {
                                    size: "small",
                                    density: "compact",
                                    "input-value": selected,
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              [
                                _c("span", { staticClass: "pr-2" }, [
                                  _vm._v(" " + _vm._s(item.mbcode_name) + " "),
                                ]),
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return parent.selectItem(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-close-circle ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedMembersToNotify,
                      callback: function ($$v) {
                        _vm.selectedMembersToNotify = $$v
                      },
                      expression: "selectedMembersToNotify",
                    },
                  }),
                ],
                1
              ),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1)
                : _vm._e(),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none rounded-lg elevation-0",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            depressed: "",
                            width: "90",
                          },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "primary text-none rounded-lg elevation-0",
                          attrs: {
                            width: "90",
                            depressed: "",
                            disabled:
                              _vm.$v.$invalid || _vm.submitButtonClicked,
                          },
                          on: { click: _vm.secondNoticeTicketSubmit },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-bottom-navigation",
                    {
                      staticClass: "px-2 py-4",
                      attrs: { fixed: "", horizontal: "", height: "70" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { justify: "space-between", "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "mr-2 text-none rounded-lg elevation-0",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                width: "50%",
                              },
                              on: { click: _vm.closeDialog },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none font-weight-regular grey--text rounded-lg elevation-0",
                              attrs: {
                                color: "primary",
                                width: "50%",
                                depressed: "",
                                disabled:
                                  _vm.$v.$invalid || _vm.submitButtonClicked,
                              },
                              on: { click: _vm.secondNoticeTicketSubmit },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "white--text text-none" },
                                [_vm._v(_vm._s(_vm.$t("submit")))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }